$font-dark: #123769;
$font-grey: #6a6f77;
$font-grey-1: #666b6d;
$font-blue: #5178e8;

$color-green: #4defa1;
$color-green-1: #00d8ab;
$color-green-2: #81dfff;

$color-red: #ff3759;
$color-pink: #ff93f2;
$color-pink-rgba-1: #ff93f2;
$color-pink-1: #efb2f7;

$color-blue: #6ea5ef;
$color-blue-1: #88b6fe;

$color-grey: #cad1d4;
$color-grey-1: #aab0b8;
$color-grey-2: #e1e1ec;
$color-grey-3: #e3e3ed;

$color-purple-1: #ac9dfc;

:root {
    --gradient-red: #5c85d6;
    --gradient-red-rgb: #fe81a6;
    --gradient-red-rgba: #b5e3d6;
    --gradient-red-rgba-4: #da1c44;
    --gradient-red-rgba-2: #50A1CA;

    --gradient-red-1: #50A1CA;
    --gradient-red-1-rgba-4: #da1c44;
    --gradient-red-1-rgba-2: rgb(80, 161, 202, .2);
    --gradient-red-1-rgba-1: rgb(80, 161, 202, .1);

    --red-1: #50A1CA;
    --red-2: #fa646e;
    --red-3: #4ca3cc;

    --drop-shadow-red: #227727;
    --drop-shadow-red-rgba-4: #febd81;

    --drop-shadow-red-1: #50A1CA;
    --drop-shadow-red-1-rgba-2:#febd81;

    --gradient-purple: #fd4e83
    ;
    --gradient-purple-rgba: #febd81;
    --gradient-purple-rgba-4: #febd81;
    --gradient-purple-rgba-2: #2952A2;

    --purple-1: #881188;
    --purple-2: #831f8f;
    --purple-3: #db4295;
    --purple-4: #50A1CA;

    --gradient-purple-1: #50A1CA;
    --gradient-purple-1-rgba: rgba(183, 14, 127, 1);
    --gradient-purple-1-rgba-4: rgba(183, 14, 127,.4);
    --gradient-purple-1-rgba-2: #b0d5e8;

    // --purple-drop-shadow: #b0d5e8;
    // --purple-drop-shadow-rgba-1:#fac49e;

    --blue: #0072ff;
    --blue-rgba-4: rgba(0,114,255,.4);
    --blue-rgba-2: rgba(0,114,255,.2);
    
    --gradient-orange: #2c54a4;
    --gradient-orange-rgba: rgb(114, 148, 218);
    --gradient-orange-rgba-4: rgba(255, 171, 101,.4);
    --gradient-orange-rgba-2: rgba(255, 171, 101,.2);

    --orange-1: #ff7560;
    --orange-2: #ffa667;

    --orange-drop-shadow: #ff9a47;
    --orange-drop-shadow-rgba-2: rgba(255, 154, 71, .2);

    --orange-drop-shadow-1: #ff5b73;
    --orange-drop-shadow-1-rgba-2: rgba(255, 91, 115, .2);

    --gradient-orange-1: #ff93f2;
    --gradient-orange-1-rgba: rgba(255,147,242, 1);
    --gradient-orange-1-rgba-4: rgba(255,147,242,.4);
    --gradient-orange-1-rgba-2: rgba(255,147,242,.2);

    --pink: #ff56ea;
    --pink-rgba-1: rgba(255, 86, 234, .1);

    --pink-1: #ff92f2;

    --border-width: .3rem;
}