a {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;
}

ul {
    padding-left: 0;
}

h1 {
    font-size: 35px;
    font-weight: 900;
    letter-spacing: -.2rem;
    color: #033F63;
    

    @media (max-width: 991px) {
        font-size: 5rem;
        // line-height: 7rem;
    }

    @media (max-width: 499px) {
        font-size: 30px;
        padding-top: 40px;
        
    }
}

h2 {
    font-size: 5rem;
    font-weight: 600;
    letter-spacing: -.2rem;

    @media (max-width: 991px) {
        font-size: 4rem;        
    }
}

h3 {
    font-size: 3rem;
    font-weight: 600;
}

h4 {
    font-size: 2rem;
    font-weight: 600;
}

h5 {
    font-size: 1.8rem;
    font-weight: 600;
}

h6 {
    font-size: 1.4rem;
    font-weight: 500;

    @media (max-width: 991px) {
        font-size: 1.6rem;
    }
}

p {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 3rem;
    margin: 0;
}

ul {
    li {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 3rem;
    }
}